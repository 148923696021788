<template>
  <div id="school-class" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <ValidationObserver ref="observer" v-slot="{ passes }">
        <div class="columns">
          <div class="column is-6 is-offset-3">
            <form @submit.prevent="passes(add)">
              <SdSelect
                rules="required"
                label="Class Group"
                v-model="schoolClass.classGroup.name"
              >
                <option
                  v-for="classGroup in classGroups"
                  :key="classGroup.name"
                >
                  {{ classGroup.name }}
                </option>
              </SdSelect>

              <SdInput
                type="text"
                rules="required"
                label="Name"
                v-model="schoolClass.name"
                placeholder="Enter a name"
              />

              <SdSelect
                type="text"
                label="Promotion Class"
                v-model="schoolClass.promotionClass.id"
              >
                <option
                  v-for="schoolClass in schoolClasses"
                  :key="schoolClass.id"
                  :value="schoolClass.id"
                >
                  {{ schoolClass.name }}
                </option>
              </SdSelect>

              <button
                type="submit"
                class="button btn-120 is-primary is-capitalized is-pulled-right mt-4"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </ValidationObserver>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import CLASS_GROUPS from '../../../graphql/class_group/ClassGroups.gql'
import SCHOOL_CLASSES from '../../../graphql/school_class/SchoolClasses.gql'
import SdInput from '../../components/SdInput'
import SdSelect from '../../components/SdSelect'
import { ValidationObserver } from 'vee-validate'
import { crudNotification } from '../../assets/js/notification'

export default {
  name: 'school-class',
  data() {
    return {
      schoolId: null,
      pageTitle: 'School Class',
      skipQuery: true,
      classGroups: [],
      schoolClasses: [],
      schoolClass: {
        id: null,
        classGroupId: null,
        classGroup: {
          name: null,
        },
        name: '',
        promotionClass: {
          id: null,
          name: null,
        },
      },
    }
  },
  apollo: {
    classGroups: {
      query: CLASS_GROUPS,
      variables() {
        return { schoolId: this.schoolId }
      },
    },
    schoolClass: {
      query: gql`
        query SchoolClassId($id: ID!) {
          schoolClass(id: $id) {
            id
            classGroupId
            name
            classGroup {
              name
            }
            promotionClass {
              id
              name
            }
          }
        }
      `,
      variables() {
        return { id: this.$route.params.id }
      },
      skip() {
        return this.skipQuery
      },
    },
    schoolClasses: {
      query: SCHOOL_CLASSES,
      variables() {
        return { schoolId: parseInt(this.$route.params.school_id) }
      },
    },
  },
  watch: {
    'schoolClass.classGroup.name'(value) {
      if (value) {
        this.schoolClass.classGroupId = this.classGroups.filter(
          (classGroup) => classGroup.name === value
        )[0].id
      }
    },
    schoolClass(data) {
      if (data.promotionClass === null) {
        data.promotionClass = {}
      }
    },
  },
  methods: {
    add() {
      if (this.schoolClass.name === this.schoolClass.promotionClass.name) {
        this.schoolClass.promotionClass.id = null
      }
      if (this.$route.params.id === 'new') {
        // Add
        this.$apollo
          .mutate({
            mutation: gql`
              mutation createSchoolClassQuery(
                $classGroupId: Int!
                $name: String!
                $promotionClassId: Int
              ) {
                createSchoolClass(
                  input: {
                    classGroupId: $classGroupId
                    name: $name
                    promotionClassId: $promotionClassId
                  }
                ) {
                  schoolClass {
                    classGroupId
                    name
                    promotionClass {
                      name
                    }
                  }
                  errors
                }
              }
            `,
            // Parameters
            variables: {
              classGroupId: parseInt(this.schoolClass.classGroupId),
              name: this.schoolClass.name.trim(),
              promotionClassId: parseInt(this.schoolClass.promotionClass.id),
            },
          })
          .then((response) => {
            crudNotification(
              response.data.createSchoolClass.errors,
              'Successfully created.'
            ).then(() => {
              this.$router.push(
                `/school/${this.$route.params.school_id}/school_classes`
              )
            })
          })
          .catch((error) => {
            console.error(error.message)
          })
      } else {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation updateSchoolClassQuery(
                $id: Int!
                $classGroupId: Int!
                $name: String!
                $promotionClassId: Int
              ) {
                updateSchoolClass(
                  input: {
                    id: $id
                    classGroupId: $classGroupId
                    name: $name
                    promotionClassId: $promotionClassId
                  }
                ) {
                  schoolClass {
                    id
                    classGroupId
                    name
                  }
                  errors
                }
              }
            `,
            variables: {
              id: parseInt(this.schoolClass.id),
              classGroupId: parseInt(this.schoolClass.classGroupId),
              name: this.schoolClass.name.trim(),
              promotionClassId: parseInt(this.schoolClass.promotionClass.id),
            },
          })
          .then((response) => {
            crudNotification(
              response.data.updateSchoolClass.errors,
              'Successfully updated.'
            ).then(() => {
              this.$router.push(
                `/school/${this.$route.params.school_id}/school_classes`
              )
            })
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
  },
  components: {
    SdInput,
    SdSelect,
    ValidationObserver,
  },
  mounted() {
    if (this.$route.params.id !== 'new') this.skipQuery = false
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'School Classes',
        route: `/school/${this.schoolId}/school_classes`,
      },
    ])
  },
}
</script>

<style lang="scss" scoped></style>
